.EventListItem {
  display: flex;
  justify-content: space-between;
  width: 100%;

  & [data-type="icon"] {
    transition: all var(--transition);
  }

  &:hover [data-type="icon"] {
    color: var(--color-primary-500);
  }

  &:hover .EventListItemName {
    color: var(--color-primary-500);
  }
}

.EventListItemIconWrap {
  color: var(--color-primary-500);

  svg {
    width: 20px;
    height: 20px;
  }
}

.EventListItemBody {
  display: flex;
  flex-direction: column;
}

.EventListItemName {
  font-size: var(--font-size-text-base);
  line-height: var(--line-height-text-base);
  font-weight: var(--font-weight-medium);
  transition: all 100ms ease-in-out;
  margin-bottom: 8px;
}

.EventListItemMeta {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  color: var(--color-neutral-500);
  font-size: var(--font-size-text-sm);
  font-weight: var(--font-weight-medium);

  @media only screen and (min-width: 1536px) {
    gap: 10px;
  }
}

.EventListItemWarning {
  display: flex;
  align-items: center;
  background-color: var(--color-primary-500);
  color: white;
  border-radius: 17px;
  font-size: var(--font-size-text-sm);
  width: fit-content;
  padding: 8px 12px;
  gap: 10px;

  span {
    font-weight: 500;
  }
}

.RatingBlockDeadlineIcon {
  svg {
    width: 18px;
    height: 18px;
  }
}

.EventListItemRow {
  display: flex;
  align-items: center;
  gap: 10px;
}

.EventListItemRows {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 8px;
}
