.TeacherDashboard {
  display: grid;
  gap: var(--spacing-5);
  grid-template-columns: repeat(6, 1fr);
  overflow: auto;
}

.TeacherDashboardStatistics {
  grid-column: 1 / span 6;
}

.TeacherDashboardClasses {
  grid-column: 1 / span 4;
  height: max-content;

  @media (max-width: 1100px) {
    grid-column: 1 / span 6;
  }

  svg {
    width: 30px;
    height: 30px;
    margin-top: var(--spacing-2);
  }
}

.TeacherDashboardRatings {
  grid-column: 5 / span 2;
  height: max-content;

  @media (max-width: 1100px) {
    grid-column: 1 / span 6;
  }
}
