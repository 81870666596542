.Pagination {
  display: flex;
  justify-content: center;
  padding-top: var(--spacing-4);
  padding-bottom: var(--spacing-4);
  width: 100%;
  nav {
    display: flex;
    gap: 0.75rem;
    a {
      display: flex;
      height: var(--spacing-8);
      align-items: center;
      justify-content: center;
      padding: 0.25rem 0.75rem;
      background-color: white;
      border-radius: 8px;
      &[aria-current="page"] {
        background-color: var(--color-secondary-500);
        color: var(--color-white);
      }
    }
  }
}
