.progressBar {
  --progressBar-border-radius: var(--border-radius-full);

  display: block;
  height: 10px;
  width: 100%;
  overflow: hidden;
  gap: var(--spacing-1);
  margin: 0;
  padding: 0;

  background-color: var(--color-neutral-200);
  border-radius: var(--progressBar-border-radius);
}

.progress {
  display: block;
  width: 100%;
  height: 10px;
}
