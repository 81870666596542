.StudentDashboard {
  display: grid;
  gap: var(--spacing-5);
  grid-template-columns: repeat(6, 1fr);
  overflow: auto;
}

.StudentDashboardStatistics {
  grid-column: 1 / span 6;
}

.StudentDashboardProgressTable {
  grid-column: 1 / span 4;
  height: max-content;

  @media (max-width: 1100px) {
    grid-column: 1 / span 6;
  }
}

.StudentDashboardOpenRatings {
  grid-column: 5 / span 2;
  height: max-content;

  @media (max-width: 1100px) {
    grid-column: 1 / span 6;
  }
}
