.DataTable {
  width: 100%;
  border-radius: 4px;
  border-collapse: collapse;
  position: relative;

  &__ClickableRow {
    cursor: pointer;
  }
  &__Required {
    color: var(--color-red-700);
    font-size: var(--font-size-text-sm);
    font-weight: var(--font-weight-medium);
    margin-left: var(--spacing-1);
  }
  &__SubRow {
    &:nth-child(even) td {
      background-color: var(--color-background);
    }
    & td > span {
      padding-left: var(--spacing-2);
    }
  }
  thead {
    tr {
      th {
        position: sticky;
        top: -24px; // compensate for padding of Block element
        font-size: var(--font-size-text-base);
        font-weight: var(--font-weight-medium);
        color: var(--color-neutral-800);
        text-align: left;
        padding: var(--spacing-3) var(--spacing-2);
        border-bottom: 1px solid var(--color-neutral-100);
        background-color: var(--color-white);
        z-index: 1;

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
  tbody {
    tr {
      &:last-child {
        td {
          border-bottom: none;
          padding-bottom: 0;
        }
      }

      td {
        padding: var(--spacing-4) var(--spacing-2);
        border-bottom: 1px solid var(--color-neutral-100);
        font-size: var(--font-size-text-base);
        font-weight: var(--font-weight-normal);
        color: var(--color-neutral-800);
        text-align: left;

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
}

.loading {
  padding: var(--spacing-3) var(--spacing-2);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-3);

  svg {
    height: var(--line-height-text-sm);
    width: var(--line-height-text-sm);
  }
}

.header {
  display: flex;
  gap: var(--spacing-2);
  align-items: center;
  justify-content: space-between;
}
