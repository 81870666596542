.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 3.1rem;
  padding-bottom: 1.5rem;

  svg {
    width: 50px;
    height: 50px;
  }
}
